<template>
  <div class="login">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-6">
          <el-input v-model="form.login" placeholder="Логин" />
        </div>
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col-6">
          <el-input v-model="form.password" type="password" placeholder="Пароль" />
        </div>
      </div>
      <div>
        <div class="col-2"></div>
        <div class="col-6">
          <el-button @click="handlerLogin">
            Login
          </el-button>
        </div>
      </div>
  </div>
</template>

<script>

import {ElButton, ElInput} from "element-plus";

export default {
  name: 'Login',
  components: {
    ElButton,
    ElInput,
  },
  data: function(){
    return {
      form: {
        login: '',
        password: '',
      }
    }
  },
  methods: {
    handlerLogin() {
      if(this.form.login === 'admin' && this.form.password === 'admin') {
        this.$store.dispatch('AUTH_REQUEST', this.form);
        this.$router.push('/');
      }
    }
  },
}
</script>
